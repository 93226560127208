import React, { useEffect, useState } from "react";
import "./style.css";
import General from "../../components/MyAccount/General";
import Billing from "../../components/MyAccount/Billing";
import TeamPermissions from "../../components/MyAccount/TeamPermissions";
import AIConfiguration from "../../components/MyAccount/AIConfiguration";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserInfo, fetchUserTopics } from "../../store/features/user/userSlice";
import { getApi } from '../../api/methods'

const MyAccount = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const tabNo = searchParams.get("invite");
  const aiConfig = searchParams.get("aiConfig");
  const dispatch = useDispatch();
  const { isDark, layoutShift } = useSelector((state) => state.common);
  const user = useSelector((state) => state.user);

  const [planName, setPlanName] = useState("Free-tier");
  const [facebookId, setFacebookId] = useState(null);
  const filterdata = ["General", "AI Configuration", "Billing", "Team & Permissions"];
  const [selectedItem, setSelectedItem] = useState(() => {
    return filterdata[tabNo] || filterdata[aiConfig] || filterdata[0];
  });

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  const getPlanName = async () => {
    try {
      if (!user.userData?.teamId) {
        throw new Error("Team ID is undefined");
      }
      const transactionData = await getApi(`transaction/get-subscription-by-id/${user.userData.teamId}`);
      setPlanName(transactionData[0]?.plan_Name || "Free-tier"); // Fallback if no plan_Name
    } catch (error) {
      console.error("Error fetching plan name:", error);
    }
  };

  const getFacebookId = async () => {
    try {
      if (!user.userData?.userId) {
        throw new Error("User ID is undefined");
      }
      const fbUserData = await getApi(`facebook/get-facebook-user?userId=${user.userData.userId}`);
      setFacebookId(fbUserData.data?.facebookId || null); // Fallback if no facebookId
    } catch (error) {
      console.error("Error fetching Facebook ID:", error);
    }
  };

  useEffect(() => {
    if (user.userData?.teamId || user.userData?.userId) {
      getPlanName();
      getFacebookId();
    }
  }, [user.userData?.teamId, user.userData?.userId]);

  useEffect(() => {
    if (user?.userData?.linkedinId) {
      dispatch(fetchUserInfo(user.userData.linkedinId));
    }
  }, [user?.userData?.linkedinId, dispatch]);

  let componentToRender;

  switch (selectedItem) {
    case "General":
      componentToRender = <General user={user} />;
      break;
    case "Billing":
      componentToRender = <Billing user={user} />;
      break;
    case "Team & Permissions":
      componentToRender = <TeamPermissions user={user} />;
      break;
    case "AI Configuration":
      componentToRender = <AIConfiguration />;
      break;
    default:
      componentToRender = null;
      break;
  }

  return (
    <div className={`py-4 ${layoutShift ? "pl-24 pr-20" : "px-4"}`}>
      <div className="row">
        {/* Navigation Tabs */}
        <div className="col-12">
          <div className="account-tabs-container overflow-x-auto">
            <div className="account-item flex items-center">
              {filterdata?.map((item, index) => (
                <button
                  key={index}
                  className={`tab-item whitespace-nowrap px-3 py-2 mr-2 rounded-md text-sm sm:text-base ${
                    selectedItem === item
                      ? `active-tab ${isDark ? "active-tab-light" : "active-tab-dark"}`
                      : isDark
                      ? "text-gray-700"
                      : "text-gray-100"
                  }`}
                  onClick={() => handleItemClick(item)}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className="col-12 mt-4">
          <div className={`content-section rounded-lg ${isDark ? "bg-white" : "bg-black"}`}>
            {componentToRender}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;