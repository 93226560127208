import React, { useEffect, useState } from "react";
import "./Headers.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { deleteNotification, deleteAllNotifications, fetchUserInfo, fetchUserNotifications, fetchUserPhoto } from "../../../store/features/user/userSlice";
import { getApi, patchApi, postApi } from "../../../api/methods";
import { changeLayoutShift, changeTheme, isLoading } from "../../../store/features/common/commonSlice";
import { fetchQueueData, fetchScheduleData } from "../../../store/features/scheduleData/scheduleDataSlice";
import { fetchUserHistory } from "../../../store/features/history/historySlice";
import { fetchUserDraft } from "../../../store/features/draft/draftSlice";
import { fetchUserBookmark } from "../../../store/features/bookmark/bookmarkSlice";
import { fetchUserEverGreen } from "../../../store/features/evergreen/evergreenSlice";
import { BASE_URL, IMAGE_URL } from "../../../api/API";
import { fetchUserOTG } from "../../../store/features/otg/otgSlice";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import axios from "axios";

const Headers = ({ notifiyModal, setNotifiyModal, switchProfileModal, setSwitchProfileModal }) => {
  const cookies = new Cookies();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userPhoto, userData, notifications, addNewNotification, userChosenTopics, teamMembers } = useSelector((state) => state.user);
  const { layoutShift, isDark } = useSelector((state) => state.common);

  // const [ notifiyModal, setNotifiyModal ] = useState(false)
  // const [ switchProfileModal, setSwitchProfileModal ] = useState(false)
  const [notification, setNotification] = useState({});
  const [sortedNotifications, setSortedNotifications] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenIndex, setIsOpenIndex] = useState(0);
  const isMobile = useMediaQuery({ query: "(max-width: 770px)" });
  const [streakCount, setStreakCount] = useState({
    currentStreak: 0,
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchStreakData = async () => {
      try {
        const response = await getApi(`daily-post-tracker/current-streak/${userData.linkedinId}`);
        setStreakCount(response);
      } catch (e) {
        console.log("Error fetching streak data:", error);
      }
    };
    fetchStreakData();
  }, [userData]);

  useEffect(() => {
    if (notifications?.personalizedNotifications?.length > 0) {
      // console.log(notifications);
      setSortedNotifications(
        [...notifications.personalizedNotifications].sort((a, b) => {
          // Assuming createdAt field is in ISO date format
          // console.log(dateA - dateB);
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        })
      );
    }
    // console.log(sortedNotifications);
    // setNotificationss(fetchedNotifications);
  }, [notifications]);
  useEffect(() => {
    console.log("sortedNotifications");
    // setSortedNotifications()

    // setNotificationss(fetchedNotifications);
  }, [sortedNotifications]);

  // save user theme light or dark
  const handleUpdatetheme = async () => {
    dispatch(changeTheme(!isDark));

    const response = await postApi("user/create-user-choosen-topics", {
      loggeduserId: userData?.linkedinId,
      isDarkmode: !isDark,
    });
    toast.success(`Switched to ${!isDark ? "Light Mode" : "Dark Mode"}!`);

    localStorage.setItem("theme", !isDark);
  };
  // Fetch data using today
  // Get the current date
  const currentDate = new Date();

  // Get the current year and month
  let getStartYear = currentDate.getFullYear();
  let getEndYear = currentDate.getFullYear();
  let getMonthNumber = currentDate.getMonth();

  // Format the month number with leading zero if needed
  getMonthNumber = getMonthNumber < 10 ? `0${getMonthNumber}` : getMonthNumber;

  // Adjust the start and end years and months based on the current month number
  if (getMonthNumber === "0-1") {
    getMonthNumber = 12;
    getStartYear = getStartYear - 1;
  } else if (getMonthNumber === "00") {
    getMonthNumber = 12;
    getStartYear = getStartYear - 1;
  }

  // Calculate the end month number and adjust the end year if needed
  let getEndMonthNumber = currentDate.getMonth() + 2;
  getEndMonthNumber = getEndMonthNumber < 10 ? `0${getEndMonthNumber}` : getEndMonthNumber;
  if (getEndMonthNumber === 13) {
    getEndMonthNumber = "01";
    getEndYear = getEndYear + 1;
  }

  // Calculate the queue start date (1 day before the current date)
  let queueStartDate = currentDate.setDate(currentDate.getDate() - 1);
  queueStartDate = new Date(queueStartDate).toISOString().split("T")[0];

  // Calculate the queue end date (7 days after the current date)
  let queueEndDate = currentDate.setDate(currentDate.getDate() + 7);
  queueEndDate = new Date(queueEndDate).toISOString().split("T")[0];

  useEffect(() => {
    // dispatch(fetchUserInfo());
    dispatch(fetchUserPhoto());
  }, []);

  // when url is change then all the top bar panel will close automatically
  useEffect(() => {
    setSwitchProfileModal(false);
    setNotifiyModal(false);
  }, [window.location.href]);

  useEffect(() => {
    // This useEffect is use for refreshing the page
    // console.log({userChosenTopics});
    dispatch(changeTheme(JSON.parse(localStorage.getItem("theme"))));
    dispatch(fetchUserNotifications(userData.linkedinId));
    dispatch(isLoading(true));
    dispatch(fetchUserHistory({ userId: userData.linkedinId, page: 1 }));
    dispatch(fetchUserDraft({ userId: userData.linkedinId, page: 1 }));
    dispatch(fetchUserBookmark({ userId: userData.linkedinId, page: 1 }));
    dispatch(fetchUserEverGreen({ userId: userData.linkedinId, page: 1 }));
    dispatch(
      fetchScheduleData({
        userId: userData.linkedinId,
        startDate: `${getStartYear}-${getMonthNumber}-25`,
        endDate: `${getEndYear}-${getEndMonthNumber}-10`,
      })
    );
    dispatch(
      fetchQueueData({
        userId: userData.linkedinId,
        startDate: queueStartDate,
        endDate: queueEndDate,
      })
    );
    dispatch(fetchUserOTG({ userId: userData?.linkedinId }));
    dispatch(isLoading(false));
    // console.log(notifications.personalizedNotifications);
  }, [userData.linkedinId]);

  // when user logout itself then their session will be false
  const updateUserSession = () => {
    patchApi("user/user-session", {
      linkedinId: userData.linkedinId,
      sessionActive: false,
    });
  };

  // logout functionality when user switch profile to another account
  const handleLogout = async (handle) => {
    await axios.post(`${BASE_URL}userprofile/logout`, {}, { withCredentials: true });

    // Clear the token and user info from Redux store
    window.location.href = "/login";
    updateUserSession();
    if (handle) {
      window.location.href = "/";
    } else {
      // Call the logoutLinkedin function to log the user out of LinkedIn
      // await logoutLinkedin('https://linkedin.com/m/logout');

      // After successful logout from LinkedIn, proceed to the LinkedIn authentication URL
      const redirectURL = `${BASE_URL}auth/callback/linkedin?isFromAccount=true`;

      // Redirect to the LinkedIn authentication URL
      window.location.href = redirectURL;
    }
  };

  const switchTeamMember = async (memberId) => {
    try {
      const response = await axios.post(
        `${BASE_URL}userprofile/switch-team-member`,
        { userId: memberId },
        {
          withCredentials: true,
        }
      );
      // console.log(response);

      if (response.data.authenticated) {
        // Handle successful switch
        console.log(response.data.message);
        window.location.reload();
        // You might want to update your app's state or redirect the user
      }
    } catch (error) {
      console.error("Error switching team member:", error);
      // Handle error (show message to user, etc.)
    }
  };

  const logoutLinkedin = (url) => {
    // Create a promise to wait for the logout process to complete
    return new Promise((resolve, reject) => {
      const win = window.open(url, "_blank", "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=10000, top=10000, width=10, height=10, visible=none");

      // Wait for a short time (e.g., 3 seconds) to ensure the logout process has completed
      setTimeout(() => {
        win.close();
        resolve();
      }, 3000);
    });
  };

  // here header name show according to the page in top bar
  const location = useLocation();
  let headersName = location.pathname.split("/")[1];
  headersName = headersName.charAt(0).toLocaleUpperCase() + headersName?.slice(1);
  console.log(headersName);
  // notification read functionality
  const closeNotifyHandler = async (notification) => {
    setIsOpen(false);
    const body = {
      userId: userData.linkedinId,
      isReadStatus: true,
    };
    await patchApi(`notification/update/${notification._id}`, body);
    dispatch(deleteNotification(notification._id));
    if (setNotification.length === 1) {
      setNotification([]);
      setSortedNotifications([]);
    }
    setNotification([]);
  };
  const closeNotifyHandlerForAll = async () => {
    setIsOpen(false);
    // console.log(userData.linkedinId);
    const response = await patchApi(`notification/update-all/${userData.linkedinId}`);
    console.log(response);
    console.log(notifications);
    dispatch(deleteAllNotifications());
    dispatch(fetchUserNotifications(userData.linkedinId));
    setSortedNotifications([]);
  };

  // If notification message is hide then this funstion is use to expand it
  const expandNotify = (index) => {
    // console.log({index})
    setIsOpenIndex(index);
    setIsOpen(true);
  };

  useEffect(() => {
    if (isMobile) {
      dispatch(changeLayoutShift(false));
    }
  }, [isMobile]);

  const MobileMenu = () => (
    <div className="lg:hidden fixed top-[60px] right-0 bg-white dark:bg-black border border-gray-200 dark:border-dark-fill-3 shadow-lg w-64 py-2 z-50 rounded-lg mx-2">
      <div className="flex flex-col">
        {/* Streak */}
        <div className="px-4 py-3 hover:bg-gray-100 dark:hover:bg-dark-fill-3 transition-colors duration-200">
          {streakCount.currentStreak > 0 ? (
            <div className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" fill="currentColor" className="h-[25px] w-[25px] text-brand-orange dark:text-dark-brand-orange">
                <g filter="url(#hot-filled_svg__filter0_i_289_12318)">
                  <path
                    fillRule="evenodd"
                    d="M9.588 2.085a1 1 0 01.97.092c2.85 1.966 4.498 4.744 5.31 6.67l.854-.885a1 1 0 011.56.154c2.177 3.38 2.211 7.383.521 10.3C17.039 21.459 13.583 22 11.977 22c-1.569 0-4.905-.27-6.825-3.584-.832-1.435-1.27-3.053-1.125-4.704.146-1.66.876-3.284 2.264-4.721.86-.891 1.505-2.122 1.957-3.322.449-1.193.68-2.278.752-2.806a1 1 0 01.588-.778z"
                    clipRule="evenodd"
                    fill="url(#hot-filled_svg__paint0_linear_289_12318)"
                  ></path>
                </g>
                <defs>
                  <linearGradient id="hot-filled_svg__paint0_linear_289_12318" x1="12" x2="12" y1="2" y2="22" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFA116"></stop>
                    <stop offset="1" stopColor="#F9772E"></stop>
                  </linearGradient>
                  <filter id="hot-filled_svg__filter0_i_289_12318" width="17.2" height="21.2" x="4" y="2" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                    <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
                    <feOffset dx="1.2" dy="1.2"></feOffset>
                    <feGaussianBlur stdDeviation="0.6"></feGaussianBlur>
                    <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
                    <feColorMatrix values="0 0 0 0 0.970833 0 0 0 0 0.05825 0 0 0 0 0 0 0 0 0.16 0"></feColorMatrix>
                    <feBlend in2="shape" result="effect1_innerShadow_289_12318"></feBlend>
                  </filter>
                </defs>
              </svg>
              <span className="ml-3 text-[16px] font-medium text-brand-orange dark:text-dark-brand-orange">{streakCount.currentStreak} Day Streak</span>
            </div>
          ) : (
            <div className="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="1em" height="1em" fill="currentColor" className="h-[25px] w-[25px] text-gray-500 dark:text-gray-400">
                <path
                  fillRule="evenodd"
                  d="M7.19 1.564a.75.75 0 01.729.069c2.137 1.475 3.373 3.558 3.981 5.002l.641-.663a.75.75 0 011.17.115c1.633 2.536 1.659 5.537.391 7.725-1.322 2.282-3.915 2.688-5.119 2.688-1.177 0-3.679-.203-5.12-2.688-.623-1.076-.951-2.29-.842-3.528.109-1.245.656-2.463 1.697-3.54.646-.67 1.129-1.592 1.468-2.492.337-.895.51-1.709.564-2.105a.75.75 0 01.44-.583zm.784 2.023c-.1.368-.226.773-.385 1.193-.375.997-.947 2.13-1.792 3.005-.821.851-1.205 1.754-1.282 2.63-.078.884.153 1.792.647 2.645C6.176 14.81 7.925 15 8.983 15c1.03 0 2.909-.366 3.822-1.94.839-1.449.97-3.446.11-5.315l-.785.812a.75.75 0 01-1.268-.345c-.192-.794-1.04-2.948-2.888-4.625z"
                ></path>
              </svg>
              <span className="ml-3 text-[16px] font-medium text-gray-500 dark:text-gray-400">0 Day Streak</span>
            </div>
          )}
        </div>

        {/* Profile */}
        <div
          className="px-4 py-3 hover:bg-gray-100 dark:hover:bg-dark-fill-3 flex items-center transition-colors duration-200"
          onClick={() => {
            setSwitchProfileModal(!switchProfileModal);
            setNotifiyModal(false);
            setIsMobileMenuOpen(false);
          }}
        >
          <div className="flex items-center w-full">
            {userPhoto?.length > 0 ? (
              <img src={userPhoto} className="rounded-full h-6 w-6 object-cover" alt={userData.userName} />
            ) : (
              <img src={`${IMAGE_URL}/images/Icons/avatar.jpg`} className="rounded-full h-6 w-6 object-cover" alt="Default avatar" />
            )}
            <span className="ml-3 text-gray-800 dark:text-gray-200">{userData.userName}</span>
          </div>
          <i className="fas fa-chevron-right ml-auto text-sm text-gray-400 dark:text-gray-500"></i>
        </div>

        {/* Notifications */}
        <div
          className="px-4 py-3 hover:bg-gray-100 dark:hover:bg-dark-fill-3 flex items-center transition-colors duration-200"
          onClick={() => {
            setNotifiyModal(!notifiyModal);
            setSwitchProfileModal(false);
            setIsMobileMenuOpen(false);
          }}
        >
          <div className="flex items-center w-full">
            <i className="fa fa-bell text-gray-600 dark:text-gray-400" style={{ fontSize: "16px" }}></i>
            <span className="ml-3 text-gray-800 dark:text-gray-200">Notifications</span>
            {(notifications?.personalizedNotifications?.length > 0 || notifications?.commonNotifications?.length > 0) && (
              <span className="ml-auto bg-brand-orange text-white rounded-full px-2 py-0.5 text-xs">{notifications?.commonNotifications?.length + notifications?.personalizedNotifications?.length || 0}</span>
            )}
          </div>
        </div>

        {/* Theme */}
        <div
          className="px-4 py-3 hover:bg-gray-100 dark:hover:bg-dark-fill-3 flex items-center transition-colors duration-200"
          onClick={() => {
            setNotifiyModal(false);
            setSwitchProfileModal(false);
            dispatch(changeTheme(!isDark));
            handleUpdatetheme();
            setIsMobileMenuOpen(false);
          }}
        >
          <div className="flex items-center w-full">
            {isDark ? <i className="fa-solid fa-moon text-gray-600 dark:text-gray-400" style={{ fontSize: "16px" }}></i> : <i className="fa fa-sun text-gray-600 dark:text-gray-400" style={{ fontSize: "16px" }}></i>}
            <span className="ml-3 text-gray-800 dark:text-gray-200">{isDark ? "Dark Mode" : "Light Mode"}</span>
          </div>
        </div>

        {/* Logout */}
        <div className="px-4 py-3 hover:bg-gray-100 dark:hover:bg-dark-fill-3 flex items-center transition-colors duration-200 text-red-600 dark:text-red-400" onClick={() => handleLogout(true)}>
          <i className="fa-solid fa-right-from-bracket" style={{ fontSize: "16px" }}></i>
          <span className="ml-3">Logout</span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div
        className={`top-header ${isDark ? "light-header" : "dark-header"}`}
        style={{
          paddingRight: isMobile ? "3rem" : layoutShift ? "3rem" : "3rem",
          paddingLeft: isMobile ? "2rem" : " ",
          width: isMobile ? "100%" : layoutShift ? "95%" : "89%",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          zIndex: 10,
        }}
      >
        <div className="minimize-sidebar-btn" title="Sidebar">
          <button
            // className={` ${layoutShift ? "collapse-toggle-menu" : "toggle-menu"}`}
            onClick={() => dispatch(changeLayoutShift(!layoutShift))}
          >
            {layoutShift ? (
              <>
                <i className="fa fa-angle-right" aria-hidden="true" style={{ cursor: "pointer" }}></i>
              </>
            ) : (
              <>
                <i className="fa fa-angle-left" aria-hidden="true" style={{ cursor: "pointer" }}></i>
              </>
            )}
          </button>
        </div>

        <h2 className="header-set">
          {(headersName?.split("-").join(" ") === "Post idea" && (
            <>
              Create <span className="highlight-header">AI Post</span>
            </>
          )) ||
            (headersName?.split("-").join(" ") === "On the go" && (
              <>
                Create <span className="highlight-header">On-the-Go</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Dashboard" && (
              <>
                <span className="highlight-header">Dashboard</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Carousel" && (
              <>
                <span className="highlight-header">Carousel</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Analytics" && (
              <>
                <span className="highlight-header">Analytics</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Calendar" && (
              <>
                <span className="highlight-header">Calendar</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Bookmarks" && (
              <>
                <span className="highlight-header">Bookmarks</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Evergreen" && (
              <>
                <span className="highlight-header">Evergreen</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "History" && (
              <>
                <span className="highlight-header">History</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Media" && (
              <>
                <span className="highlight-header">Media</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Discover" && (
              <>
                Discover <span className="highlight-header max-sm:text-[10px]">Content</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "My queue" && (
              <>
                My <span className="highlight-header">Queue</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Credit history" && (
              <>
                Credit <span className="highlight-header">History</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "My accounts" && (
              <>
                My <span className="highlight-header">Accounts</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Ai-content" && (
              <>
                {" "}
                <span className="highlight-header">AI Content</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "PostAnalytics" && (
              <>
                {" "}
                <span className="highlight-header">Post Analytics</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Buy subscription" && (
              <>
                {" "}
                <span className="highlight-header">Buy Subscription</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Order review" && (
              <>
                {" "}
                <span className="highlight-header">Order Review</span>
              </>
            )) ||
            (headersName?.split("-").join(" ") === "Help desk" && (
              <>
                Help <span className="highlight-header">Desk</span>
                <i class="fa fa-info-circle cursor-pointer" style={{ fontSize: "16px", marginLeft: "10px" }} title="Before Logging in here, Please signup on https://growero.productlift.dev/"></i>
                <a href="https://growero.productlift.dev/" target="_blank">
                  <button className={`text-sm ${isDark ? "text-black" : "text-white"} underline signupBtn`}>Sign Up</button>
                </a>
              </>
            )) ||
            (headersName?.split("-").join(" ") && <span className="highlight-header">AI Content</span>) ||
            "Dashboard"}
        </h2>
        <div className="right-side" title="Streak">
          {/* Hide original elements on mobile */}
          <div className="hidden lg:flex items-center space-x-4">
            {streakCount.currentStreak > 0 ? (
              <div className="mt-[4px]">
                <a className="group relative flex h-8 items-center justify-center rounded p-1 hover:bg-fill-3 dark:hover:bg-dark-fill-3 cursor-default">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" fill="currentColor" className="h-[50px] w-[25px]">
                    <g filter="url(#hot-filled_svg__filter0_i_289_12318)">
                      <path
                        fillRule="evenodd"
                        d="M9.588 2.085a1 1 0 01.97.092c2.85 1.966 4.498 4.744 5.31 6.67l.854-.885a1 1 0 011.56.154c2.177 3.38 2.211 7.383.521 10.3C17.039 21.459 13.583 22 11.977 22c-1.569 0-4.905-.27-6.825-3.584-.832-1.435-1.27-3.053-1.125-4.704.146-1.66.876-3.284 2.264-4.721.86-.891 1.505-2.122 1.957-3.322.449-1.193.68-2.278.752-2.806a1 1 0 01.588-.778z"
                        clipRule="evenodd"
                        fill="url(#hot-filled_svg__paint0_linear_289_12318)"
                      ></path>
                    </g>
                    <defs>
                      <linearGradient id="hot-filled_svg__paint0_linear_289_12318" x1="12" x2="12" y1="2" y2="22" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#FFA116"></stop>
                        <stop offset="1" stopColor="#F9772E"></stop>
                      </linearGradient>
                      <filter id="hot-filled_svg__filter0_i_289_12318" width="17.2" height="21.2" x="4" y="2" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"></feColorMatrix>
                        <feOffset dx="1.2" dy="1.2"></feOffset>
                        <feGaussianBlur stdDeviation="0.6"></feGaussianBlur>
                        <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"></feComposite>
                        <feColorMatrix values="0 0 0 0 0.970833 0 0 0 0 0.05825 0 0 0 0 0 0 0 0 0.16 0"></feColorMatrix>
                        <feBlend in2="shape" result="effect1_innerShadow_289_12318"></feBlend>
                      </filter>
                    </defs>
                  </svg>
                  <span className="mx-1 text-[20px] font-medium text-brand-orange dark:text-dark-brand-orange">{streakCount.currentStreak}</span>
                </a>
              </div>
            ) : (
              <div className="flex flex-row">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width="1em" height="1em" fill="currentColor" className="h-[50px] w-[25px] hover:text-text-primary dark:hover:text-text-primary text-text-secondary dark:text-text-secondary">
                  <path
                    fillRule="evenodd"
                    d="M7.19 1.564a.75.75 0 01.729.069c2.137 1.475 3.373 3.558 3.981 5.002l.641-.663a.75.75 0 011.17.115c1.633 2.536 1.659 5.537.391 7.725-1.322 2.282-3.915 2.688-5.119 2.688-1.177 0-3.679-.203-5.12-2.688-.623-1.076-.951-2.29-.842-3.528.109-1.245.656-2.463 1.697-3.54.646-.67 1.129-1.592 1.468-2.492.337-.895.51-1.709.564-2.105a.75.75 0 01.44-.583zm.784 2.023c-.1.368-.226.773-.385 1.193-.375.997-.947 2.13-1.792 3.005-.821.851-1.205 1.754-1.282 2.63-.078.884.153 1.792.647 2.645C6.176 14.81 7.925 15 8.983 15c1.03 0 2.909-.366 3.822-1.94.839-1.449.97-3.446.11-5.315l-.785.812a.75.75 0 01-1.268-.345c-.192-.794-1.04-2.948-2.888-4.625z"
                  ></path>
                </svg>
                <span className="mx-1 mt-[10px] text-[20px] font-medium hover:text-text-primary dark:hover:text-text-primary text-text-secondary dark:text-text-secondary">0</span>
              </div>
            )}
            <div
              className={`top-icons mobileCheck switch-profile ${isDark ? `border-light` : "border-dark"}`}
              onClick={() => {
                setSwitchProfileModal(!switchProfileModal);
                setNotifiyModal(false);
              }}
              title="Switch Profile"
            >
              {userPhoto?.length > 0 ? (
                <img src={userPhoto} className="avatar-img" style={{ borderRadius: "50%", height: "24px", width: "24px" }} />
              ) : (
                <img src={`${IMAGE_URL}/images/Icons/avatar.jpg`} className="avatar-img" style={{ borderRadius: "50%", height: "24px", width: "24px" }} />
              )}
              <span className="hide-on-small">{userData.userName}</span>
            </div>
            <div
              className={`${isDark ? `border-light` : "border-dark"} top-icons notify-div`}
              onClick={() => {
                setNotifiyModal(!notifiyModal);
                setSwitchProfileModal(false);
              }}
              title="Notification"
            >
              <i className="fa fa-bell" style={{ fontSize: "16px" }} aria-hidden="true"></i>
              {(notifications?.personalizedNotifications?.length > 0 || notifications?.commonNotifications?.length > 0) && (
                <p className="notify-badge">{notifications?.commonNotifications?.length + notifications?.personalizedNotifications?.length || 0}</p>
              )}
            </div>

            <div
              className={isDark ? `top-icons border-light` : "top-icons btn-theme border-dark"}
              onClick={() => {
                setNotifiyModal(false);
                setSwitchProfileModal(false);
                dispatch(changeTheme(!isDark));
                handleUpdatetheme();
              }}
              title="Theme"
            >
              {isDark ? <i className="fa-solid fa-moon" style={{ fontSize: "16px" }}></i> : <i className="fa fa-moon" style={{ fontSize: "16px", color: "white" }} aria-hidden="true"></i>}
            </div>
            <div className="logout-btn" title="Logout">
              <button className={`${isDark ? `border-light` : "border-dark"}`} onClick={() => handleLogout(true)}>
                <i className="fa-solid fa-right-from-bracket" style={{ fontSize: "16px" }}></i>
              </button>
            </div>
          </div>

          {/* More options menu for mobile */}
          <div className="lg:hidden">
            <button className="p-2 hover:bg-gray-100 dark:hover:bg-dark-fill-3 rounded-lg transition-colors duration-200" onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} title="More options">
              <i className={`fas ${isMobileMenuOpen ? "fa-times" : "fa-ellipsis-v"} text-xl text-gray-600 dark:text-gray-300`}></i>
            </button>
            {isMobileMenuOpen && <MobileMenu />}
          </div>
        </div>
      </div>

      {notifiyModal && (
        <>
          <div className={`notifyModel scrollbar-morpheus-den ${isDark ? `border-light` : "border-dark"}`}>
            <div className="col-md-12 col-lg-12">
              <div className="panel panel-default">
                <div className="panel-heading">
                  <h6 className="panel-title mb-2">Notifications</h6>
                  <div className="flex w-full items-center justify-center">
                    <span
                      className="read-btn cursor-pointer"
                      onClick={() => {
                        closeNotifyHandlerForAll();
                        setNotifiyModal(false);
                      }}
                    >
                      Mark All as Read
                    </span>
                  </div>
                  <hr />
                  <p className="mb-2"></p>
                </div>
                <div className="panel-body">
                  {
                    // console.log(notification)
                    Object.keys(notifications)?.length > 0 && notifications?.personalizedNotifications?.length === 0 && notifications?.commonNotifications?.length === 0 && (
                      <div
                        className="alert center"
                        style={{ textAlign: "center" }}
                        onClick={() => {
                          setNotifiyModal(true);
                        }}
                      >
                        <strong className="default">
                          <i className="fa-regular fa-bell-slash" style={{ color: "red" }}></i> No Notification{" "}
                        </strong>
                      </div>
                    )
                  }
                  {/* <p style={{color:'#0E69C3',textShadow:'0 2px 4px rgba(0,0,0,0.5)'}}>Personalized</p> */}
                  {notifications &&
                    Array.isArray(sortedNotifications) &&
                    sortedNotifications.map((item, i) => {
                      // console.log(notificatios?.personalizedNotifications);
                      console.log(notifications);

                      if (isOpen && isOpenIndex === i) {
                        return (
                          <div className="alert d-flex flex-column personal-notify" key={i}>
                            <strong className="default">
                              <i className="fa-regular fa-bell fa-shake" style={{ color: "#215fca" }}></i> {item.message}{" "}
                            </strong>
                            <div className="d-flex justify-content-between">
                              <p className="date">{item.createdAt?.split("T")[0]}</p>
                              <span
                                className="read-btn"
                                onClick={() => {
                                  setNotification(notification);
                                  setNotifiyModal(false);
                                  closeNotifyHandler(item);
                                }}
                              >
                                Mark as Read
                              </span>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="alert d-flex flex-column personal-notify" key={i}>
                            <strong className="default" onClick={() => expandNotify(i)}>
                              <i className="fa-regular fa-bell fa-shake" style={{ color: "#215fca" }}></i> {item.message?.slice(0, 30)}
                              {item.message?.length > 25 ? "..." : ""}{" "}
                            </strong>
                            <div className="d-flex justify-content-between">
                              <p className="date">{item.createdAt?.split("T")[0]}</p>
                              <span
                                className="read-btn"
                                onClick={() => {
                                  setNotification(notification);
                                  setNotifiyModal(false);
                                  closeNotifyHandler(item);
                                }}
                              >
                                Mark as Read
                              </span>
                            </div>
                          </div>
                        );
                      }
                    })}
                  {/* <p style={{color:'#4D9AD0',textShadow:'0 2px 4px rgba(0,0,0,0.5)'}}>Common</p> */}
                  {notifications &&
                    notifications?.commonNotifications?.map((item, i) => {
                      if (isOpen && isOpenIndex === i) {
                        return (
                          <div className="alert d-flex flex-column common-notify" key={i}>
                            <strong className="default">
                              <i className="fa-regular fa-bell fa-shake" style={{ color: "#215fca" }}></i> {item.message}{" "}
                            </strong>
                            <div className="d-flex justify-content-between">
                              <p className="date">{item.createdAt?.split("T")[0]}</p>
                              <span
                                className="read-btn"
                                onClick={() => {
                                  setNotification(notification);
                                  setNotifiyModal(false);
                                  closeNotifyHandler(item);
                                }}
                              >
                                Mark as Read
                              </span>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="alert d-flex flex-column common-notify" key={i}>
                            <strong className="default" onClick={() => expandNotify(i)}>
                              <i className="fa-regular fa-bell fa-shake" style={{ color: "#215fca" }}></i> {item.message?.slice(0, 30)}
                              {item.message?.length > 25 ? "..." : ""}{" "}
                            </strong>
                            <div className="d-flex justify-content-between">
                              <p className="date">{item.createdAt?.split("T")[0]}</p>
                              <span
                                className="read-btn"
                                onClick={() => {
                                  setNotification(notification);
                                  setNotifiyModal(false);
                                  closeNotifyHandler(item);
                                }}
                              >
                                Mark as Read
                              </span>
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* <div>
          {
            notification && isOpen && <div className={`offer-box ${ isDark ? `border-light` : 'border-dark'}`}>
                <div className=" row offer-body" >

                  <div className=' text-dark'>
                    <h4 className='center' style={{textAlign:"center"}} >Notification</h4>
                    <h5 style={{fontSize:'15px',marginTop:"3rem",padding:0, color:"black"}}><b>Message:</b> {notification?.message}</h5>
                  </div>
                    <div>
                      
                    </div>
                  <div className='close-btn' onClick={closeNotifyHandler}>
                    <img className='close-img' src='https://cdn3.iconfinder.com/data/icons/pyconic-icons-1-2/512/close-circle-1024.png'/>
                  </div>
                </div>
            </div>
          }
        </div> */}

      <div>
        {switchProfileModal && (
          <div className={`switch-profile-panel ${isDark ? `border-light` : "border-dark"}`}>
            <div className="panel panel-default">
              <div className="panel-body">
                {teamMembers?.map((member, i) => {
                  if (member.id !== userData.id && member.userName)
                    return (
                      <div
                        key={i}
                        className={`switch-profile-person ${member.teamPrimary && "is-Leader"} `}
                        onClick={() => {
                          console.log("hi");
                          // alert("Clicked!");
                          console.log(member);
                          switchTeamMember(member.userId);
                        }}
                      >
                        <div className="d-flex flex-row align-items-center">
                          <div>
                            {member.linkedinProfile?.length > 0 ? <img src={member.linkedinProfile} className="avatar-img p-1 w-9 rounded-full" /> : <img src={`${IMAGE_URL}/images/Icons/avatar.jpg`} className="avatar-img p-1 w-9 rounded-full" />}
                          </div>
                          <div>
                            <span style={{ fontSize: "0.7rem" }}>{member.userName}</span>
                          </div>
                        </div>
                        {/* { member.teamPrimary &&  <i className="fa-solid fa-check"></i>} */}
                      </div>
                    );
                })}
                <div
                  className="switch-profile-person"
                  onClick={() => {
                    navigate("/my-accounts?invite=3");
                    setSwitchProfileModal(false);
                  }}
                >
                  <div className="col-lg-4">
                    <i className="fa-solid  fa-circle-plus" style={{ color: "#326bcd" }}></i>
                  </div>
                  <div className="col-lg-8 p-2">
                    <p>Add member</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Headers;
