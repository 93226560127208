import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Eye, EyeOff, UserPlus, CheckCircle, XCircle } from "lucide-react";
import { BASE_URL, IMAGE_URL } from "../../api/API";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordStrength, setPasswordStrength] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    lengthCriteria: false,
    upperCaseCriteria: false,
    lowerCaseCriteria: false,
    numberCriteria: false,
    specialCharCriteria: false,
  });
  const [referralCode, setReferralCode] = useState(null);
  const [campaign, setCampaign] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    // Initialize Referral Rocket campaign
    const initializeReferralRocket = async () => {
      if (window.Rocket) {
        const campaignInstance = window.Rocket.getCampaign();
        setCampaign(campaignInstance);

        // Get referral code from URL or cookie
        const code = window.Rocket.getReferralCode();
        if (code) {
          const isValid = await window.Rocket.validateCode(code);
          if (isValid) {
            setReferralCode(code);
          }
        }
      }
    };

    initializeReferralRocket();
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      // First register the user
      const response = await axios.post(`${BASE_URL}userprofile/register`, {
        email,
        password,
      });

      // If registration is successful, add participant to Referral Rocket
      if (campaign) {
        try {
          await campaign.addParticipant({
            email,
            referredByCode: referralCode, // This will be null if there's no referral
          });

          // If this is a referred signup, qualify the participant
          if (referralCode) {
            await campaign.qualifyParticipant({
              email,
              rewardReferee: true, // Give reward to both referrer and referee
            });
          }
        } catch (referralError) {
          console.error("Referral tracking error:", referralError);
        }
      }

      setSuccessMessage("Registration successful. Please verify your email before login.");
      setEmail("");
      setPassword("");
      setPasswordStrength("");
      setPasswordCriteria({
        lengthCriteria: false,
        upperCaseCriteria: false,
        lowerCaseCriteria: false,
        numberCriteria: false,
        specialCharCriteria: false,
      });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("User already exists");
      } else {
        setError("Registration failed. Please try again.");
      }
      console.error("Registration error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const calculatePasswordStrength = (password) => {
    const lengthCriteria = password.length >= 8;
    const upperCaseCriteria = /[A-Z]/.test(password);
    const lowerCaseCriteria = /[a-z]/.test(password);
    const numberCriteria = /[0-9]/.test(password);
    const specialCharCriteria = /[^A-Za-z0-9]/.test(password);

    setPasswordCriteria({
      lengthCriteria,
      upperCaseCriteria,
      lowerCaseCriteria,
      numberCriteria,
      specialCharCriteria,
    });

    let strength = "";
    if (
      lengthCriteria &&
      upperCaseCriteria &&
      lowerCaseCriteria &&
      numberCriteria &&
      specialCharCriteria
    ) {
      strength = "Strong";
    } else if (
      lengthCriteria &&
      (upperCaseCriteria || lowerCaseCriteria) &&
      (numberCriteria || specialCharCriteria)
    ) {
      strength = "Moderate";
    } else {
      strength = "Weak";
    }

    setPasswordStrength(strength);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    calculatePasswordStrength(newPassword);
  };

  const allCriteriaMet =
    passwordCriteria.lengthCriteria &&
    passwordCriteria.upperCaseCriteria &&
    passwordCriteria.lowerCaseCriteria &&
    passwordCriteria.numberCriteria &&
    passwordCriteria.specialCharCriteria;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-indigo-800 to-gray-300">
      <div className="w-full max-w-md p-8 space-y-6 bg-white bg-opacity-80 rounded-2xl shadow-2xl backdrop-blur-lg backdrop-filter">
        {referralCode && (
          <div className="text-sm text-green-600 bg-green-100 p-3 rounded-md">
            You were referred by a friend! 🎉
          </div>
        )}
        <div className="text-center">
          <div className="flex justify-center items-center">
            <img src={`${IMAGE_URL}/images/logo.svg`} alt="logo" className="w-36 h-auto p-2 mb-2" />
          </div>
          <h2 className="text-3xl font-extrabold text-gray-800 tracking-tight">
            Create an Account
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Sign up now to start your journey with Growero!
          </p>
        </div>

        {successMessage ? (
          <div className="text-center space-y-4">
            <div className="flex justify-center text-green-600">
              <CheckCircle size={64} strokeWidth={1.5} />
            </div>
            <p className="text-green-700 font-medium">{successMessage}</p>
            <button
              onClick={() => navigate("/login")}
              className="w-full py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 transition-colors duration-300"
            >
              Go to Login
            </button>
          </div>
        ) : (
          <form onSubmit={handleRegister} className="space-y-6">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                id="email"
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-400 bg-gray-100 bg-opacity-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
                placeholder="you@example.com"
              />
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div className="relative">
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="new-password"
                  required
                  value={password}
                  onChange={handlePasswordChange}
                  className="w-full px-3 py-2 mt-1 text-gray-900 placeholder-gray-400 bg-gray-100 bg-opacity-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-300"
                  placeholder="Create a strong password :)"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500 hover:text-blue-600"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>

              {password && (
                <div className="mt-2 p-3 bg-gray-100 rounded-md">
                  <p className="text-xs font-medium text-gray-700 mb-2">Password Requirements:</p>
                  <ul className="space-y-1 text-xs">
                    <li
                      className={`flex items-center ${
                        passwordCriteria.lengthCriteria ? "text-green-600" : "text-gray-500"
                      }`}
                    >
                      {passwordCriteria.lengthCriteria ? (
                        <CheckCircle size={12} className="mr-2" />
                      ) : (
                        <XCircle size={12} className="mr-2" />
                      )}
                      At least 8 characters
                    </li>
                    <li
                      className={`flex items-center ${
                        passwordCriteria.upperCaseCriteria ? "text-green-600" : "text-gray-500"
                      }`}
                    >
                      {passwordCriteria.upperCaseCriteria ? (
                        <CheckCircle size={12} className="mr-2" />
                      ) : (
                        <XCircle size={12} className="mr-2" />
                      )}
                      Upper case letters (A-Z)
                    </li>
                    <li
                      className={`flex items-center ${
                        passwordCriteria.lowerCaseCriteria ? "text-green-600" : "text-gray-500"
                      }`}
                    >
                      {passwordCriteria.lowerCaseCriteria ? (
                        <CheckCircle size={12} className="mr-2" />
                      ) : (
                        <XCircle size={12} className="mr-2" />
                      )}
                      Lower case letters (a-z)
                    </li>
                    <li
                      className={`flex items-center ${
                        passwordCriteria.numberCriteria ? "text-green-600" : "text-gray-500"
                      }`}
                    >
                      {passwordCriteria.numberCriteria ? (
                        <CheckCircle size={12} className="mr-2" />
                      ) : (
                        <XCircle size={12} className="mr-2" />
                      )}
                      Numbers (0-9)
                    </li>
                    <li
                      className={`flex items-center ${
                        passwordCriteria.specialCharCriteria ? "text-green-600" : "text-gray-500"
                      }`}
                    >
                      {passwordCriteria.specialCharCriteria ? (
                        <CheckCircle size={12} className="mr-2" />
                      ) : (
                        <XCircle size={12} className="mr-2" />
                      )}
                      Special characters (e.g. !@#$%^&*)
                    </li>
                  </ul>
                </div>
              )}
            </div>

            {error && <div className="p-3 text-sm text-red-700 bg-red-100 rounded-md">{error}</div>}

            <button
              type="submit"
              disabled={!allCriteriaMet || isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300 disabled:opacity-50"
            >
              {isLoading ? (
                <span className="animate-pulse">Creating Account...</span>
              ) : (
                <span className="flex items-center">
                  <UserPlus className="mr-2" size={20} /> Sign Up
                </span>
              )}
            </button>
          </form>
        )}

        {!successMessage && (
          <div className="text-center">
            <p className="text-sm text-gray-600">
              Already have an account?{" "}
              <a
                href="/login"
                className="text-blue-600 hover:text-blue-800 hover:underline font-semibold transition-colors"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/login");
                }}
              >
                Sign In
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Register;
